
































































































































































































































import { Component, Mixins } from 'vue-property-decorator';
import { ListingFilter, ListingOrderBy } from 'client-website-ts-library/filters';
import { ListingStatus, ListingCategory } from 'client-website-ts-library/types';
import { View } from 'client-website-ts-library/plugins';

import Listings from '@/components/Listings.vue';
import VideoBanner from '@/components/VideoBanner.vue';
import BuyerAgentListings from '@/components/BuyerAgentListings.vue';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Listings,
    VideoBanner,
    BuyerAgentListings,
    Form,
  },
})
export default class BuyersAgent extends Mixins(View) {
  private listingFilter: ListingFilter = new ListingFilter({
    Statuses: [
      ListingStatus.Sold,
      ListingStatus.Leased,
    ],
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Rural,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    PageSize: 9,
    OrderByStatements: [
      ListingOrderBy.CreateDateDesc,
    ],
  });

  toggle(id: string) {
    const element = document.getElementById(id);

    if (element) {
      if (element!.className === '') {
        element!.classList.add('hide');
      } else {
        element!.classList.remove('hide');
      }
    }
  }
}
